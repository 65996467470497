import React, { Component } from 'react';
import ConsoleLine from "../ConsoleLine";


class StartupCommand extends Component {

    constructor(props) {
        super(props);
        this.state = {
            output: null
        }
    }

    makeOutput(ngspiceVersion, ngspiceJsonCliVersion, message){
        return message.concat(
            '\n',
            "Ngspice Version : ", ngspiceVersion, "\n",
            "ngspice-json-cli Version : ", ngspiceJsonCliVersion
        )
    }

    async componentDidMount() {
        this.props.changeOnProcess(true);
        let response = await fetch("https://api.ngspicecloud.com/version", {
            method: 'GET',
        });

        let t = await response.json();
        let ngspice = t.ngspice;
        let message = t.message;
        let jsoncli = t['ngspice-json-cli'];

        this.setState({output: this.makeOutput(ngspice, jsoncli, message)});
        this.props.changeOnProcess(false);
    }


    render() {
            return (
                <ConsoleLine command={"ngspice-json-cli version"} output={this.state.output}/>
            )
    }
}

export default StartupCommand;
