import React, { Component } from 'react';
import ConsoleLine from "../ConsoleLine";
import Loader from "react-loader-spinner";
import Pretty_TABULARCONTENTS from "../prettyOutput/Pretty_TABULARCONTENTS";
import Pretty_NGSPICE_CLI_ERROR from "../prettyOutput/Pretty_NGSPICE_CLI_ERROR";
import Pretty_NodeSelectorProcessor from "../prettyOutput/Pretty_NodeSelectorProcessor";


class SimulateCommand extends Component {

    constructor(props) {
        super(props);
        this.state = {
            output: null,
            child: []
        }
    }

    makeOutput(output){
        let r = [];
        let contentsOfNodes = null;
        let contentsOfTabularContents = null;
        let contentsOfSpecificPrint = null;
        output = JSON.parse(output);
        output.forEach(
            function (element){
               switch (element['type']){
                   case "NGSPICE_CLI_ERROR":
                       r.push(<Pretty_NGSPICE_CLI_ERROR  title={"NGSPICE_CLI_ERROR"} contents={element['contents']}/>);
                       break;
                   case "TABULARCONTENTS":
                       r.push(<Pretty_TABULARCONTENTS  title={"TABULARCONTENTS"} contents={element['contents']}/>);
                       contentsOfTabularContents = element['contents'];
                       break;
                   case "SPECIFIC_PRINT":
                       r.push(<Pretty_TABULARCONTENTS   title={"SPECIFIC_PRINT"} contents={element['contents']}/>);
                       contentsOfSpecificPrint = element['contents'];
                       break;
                   case "INITIAL_TRANSIENT_SOLUTION":
                       r.push(<Pretty_TABULARCONTENTS title={"INITIAL_TRANSIENT_SOLUTION"} contents={element['contents']}/>);
                       contentsOfNodes = element['contents'];
                       break;
               }
            });

            r.push
            (
                <Pretty_NodeSelectorProcessor
                    title={"INITIAL_TRANSIENT_SOLUTION"}
                    specificPrint={contentsOfSpecificPrint}
                    tabularContents={contentsOfTabularContents}
                    nodes={contentsOfNodes}
                />

            );


        return r
    }


    async componentDidMount() {
        this.props.changeOnProcess(true);
        let myHeaders = new Headers();
        myHeaders.append("content-type", "multipart/form-data; boundary=--------------------------954676645787017261518128");

        let raw = "----------------------------954676645787017261518128\r\n" +
            "Content-Disposition: form-data; name=\"file\"; filename=\"default_test_circuit.cir\"\r\n" +
            "Content-Type: application/octet-stream\r\n\r\n"+
            this.props.code+
            "\r\n----------------------------954676645787017261518128--\r\n";

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        let result = await fetch("https://api.ngspicecloud.com/run", requestOptions)
            .then(response => response.text())
            // .then(result => console.log(result))
            // .catch(error => console.log('error', error));
        let child = this.makeOutput(result);
        this.setState({output: result, child: child});
        this.props.changeOnProcess(false);
    }


    render() {
            return (
                <ConsoleLine command={"ngspice-json-cli run --command='show all' --file='myCircuit.cir'"} output={this.state.output} hideOutput={true}>
                    {this.state.child === null ? <Loader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={30}
                        width={30}
                    />:  <div>{this.state.child.map(line => {
                        return (<div>{React.cloneElement(line, {addNewCommands: this.props.addNewCommands})}</div>)
                    })}</div>}
                </ConsoleLine>
            )
    }
}

export default SimulateCommand;
