import React, { Component } from 'react';
import NodeSelector from "../../../modal/NodeSelector";

class Pretty_NodeSelectorProcessor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nodes: []
        }

    }

    componentDidMount() {
        let nodes = [];
        for(let eachNodeIdx=0; eachNodeIdx<this.props.nodes.length; eachNodeIdx++) {
            nodes.push(this.props.nodes[eachNodeIdx]["node"])
        }
        this.setState({nodes: nodes});
    }

    render() {

        return (
            <div style={{paddingTop: "10px"}}>
                {
                    this.props.specificPrint.length===0 &&this.props.tabularContents.length===0&& this.state.nodes.length !== 0?
                        <NodeSelector addNewCommands={this.props.addNewCommands} nodes={this.state.nodes}/>: <div/>
                }
            </div>
        );
    }
}

export default Pretty_NodeSelectorProcessor;
