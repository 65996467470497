import React, { Component } from 'react';


class Footer extends Component {
    render() {
        const defaultLiStyle = {
            float: "left",
            marginLeft: '10px',
            color: "#666",
            fontSize: '12px',
            cursor: 'pointer'
        }
        return (
            <footer style={{bottom:0, position:"relative", width: "100%", paddingBottom: "10px", overflow: 'auto'}}>
                <div style={{padding: '10px'}}></div>
                <div style={{textAlign: "center", fontSize: "14px", color:"#5a5a5a"}}>
                    © 2020 ~  •  ngspicecloud.com (VERSION: 424027c) <br />
                    'Ngspice' trademarks of 'The ngspice team' and Ngspice Cloud provided using ngspice-json-cli. <br />
                    This program is for educational purposes only.
                    We are disclaimed from any liability arising from the use of the program.
                </div>

                <div style={{textAlign: 'center', marginTop: "10px"}}>
                    <ul style={{listStyle:"none", margin:0, padding:0, display: 'inline-block'}}>
                        <li
                            style={defaultLiStyle}
                            onClick=
                                {()=> window.open
                                    (
                                    "https://github.com/devkingsejong/ngspice-json-cli", "_blank"
                                    )
                                }
                        >
                            About ngspice-json-cli
                        </li>
                        <li style={defaultLiStyle} onClick={()=> window.open("/terms")}>Terms & Condition</li>
                        <li style={defaultLiStyle} onClick={()=> window.open("/terms")}>Privacy Policy</li>
                        <li style={defaultLiStyle} onClick={()=> window.open("", "_blank")}>Remove AD</li>
                    </ul>
                </div>

            </footer>
        );
    }
}

export default Footer;
