import React, { Component } from 'react';
import logo from '../../images/logo_trans.png'
import { Link } from 'react-router-dom';
import Coffee from "../etc/Coffee";


class Header extends Component {
    render() {
        return (
            <header style={{height:'75px', border:'1px solid #ccc', paddingLeft: '40px', paddingRight: '40px'}}>
                <div style={{lineHeight: '75px', float: 'left'}}>
                    <Link to="/main">
                        <div style={{cursor: 'pointer'}}>
                            <img
                                style={{width:'350px', height:'37px', verticalAlign: 'middle'}}
                                src={logo}
                            />
                        </div>
                    </Link>
                </div>

                <div
                    style={
                        {
                            float: "right",
                            marginRight: "30px",
                            height: "100%",
                            display: "flex",
                            alignItems: "center"
                        }
                    }
                >
                    <div
                        style={
                            {
                                width: "215px",
                                border: "2px solid #015ec0",
                                height: "40px",
                                borderRadius: '5px',
                                lineHeight: '40px',
                                cursor: "pointer"
                            }
                        }
                        onClick={()=> window.open("https://www.buymeacoffee.com/ngspicecloud")}
                    >
                        <div
                            style={
                                {
                                    color: '#333',
                                    fontSize: "25px",
                                    fontWeight: "500",
                                    textAlign: 'center',
                                }
                            }
                        >
                            <div style={{float: "left", padding: "2px"}}>
                                <img src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="Buy me a coffee" style={{width: "23px"}} />
                            </div>
                            <div style={{float: "left", paddingLeft:"8px"}}>
                                Buy me a coffee
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
