import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Header from "./pages/common/Header";
import Footer from "./pages/common/Footer";
import Tracker from '@openreplay/tracker';

const tracker = new Tracker({
  projectKey: "guuNh4EfG3qoD6k4S3EA",
});

const Root = () => {
    useEffect(() => {
        tracker.start();
    }, []);

    return (
        <BrowserRouter>
            <Header/>
            <div style={{height: "calc(100vh - 190px)", overflow: "hidden", width: "100%", minHeight: "450px"}}>
                <App style={{height: "100%"}} />
            </div>
            <Footer />
        </BrowserRouter>
    );
};

export default Root;
