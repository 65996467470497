import React, { Component } from 'react';
import ConsoleLine from "../ConsoleLine";
import Loader from "react-loader-spinner";
import Pretty_TABULARCONTENTS from "../prettyOutput/Pretty_TABULARCONTENTS";
import Pretty_NGSPICE_CLI_ERROR from "../prettyOutput/Pretty_NGSPICE_CLI_ERROR";


class SimulateCommand extends Component {

    constructor(props) {
        super(props);
        this.state = {
            output: null,
            child: []
        }
    }

    makeOutput(output){
        let r = []
        output = JSON.parse(output);
        output.forEach(
            function (element){
               switch (element['type']){
                   case "TABULARCONTENTS":
                       r.push(<Pretty_TABULARCONTENTS title={"TABULARCONTENTS"} contents={element['contents']}/>);
                       break;
                   case "SPECIFIC_PRINT":
                       r.push(<Pretty_TABULARCONTENTS title={"SPECIFIC_PRINT"} contents={element['contents']}/>);
                       break;
               }
            });

        return r
    }


    async componentDidMount() {
        this.props.changeOnProcess(true);
        let myHeaders = new Headers();
        myHeaders.append("content-type", "multipart/form-data; boundary=--------------------------954676645787017261518128");

        let raw = "----------------------------954676645787017261518128\r\n" +
            "Content-Disposition: form-data; name=\"file\"; filename=\"default_test_circuit.cir\"\r\n" +
            "Content-Type: application/octet-stream\r\n\r\n"+
            this.props.code+
            "\r\n----------------------------954676645787017261518128\r\n"+
            "Content-Disposition: form-data; name=\"figure\"\r\n\r\n"+
            this.props.figure.join(",")+
            "\r\n----------------------------954676645787017261518128--\r\n";

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        let result = await fetch("https://api.ngspicecloud.com/run", requestOptions)
            .then(response => response.text())
            // .then(result => console.log(result))
            // .catch(error => console.log('error', error));
        let child = this.makeOutput(result);
        this.setState({output: result, child: child});
        this.props.changeOnProcess(false);
    }


    render() {
            return (
                <ConsoleLine command={"ngspice-json-cli run --command='print "+this.props.figure+"' --file='myCircuit.cir'"} output={this.state.output} hideOutput={true}>
                    {this.state.child === null ? <Loader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={30}
                        width={30}
                    />:  <div>{this.state.child.map(line => {
                        return (<div>{line}</div>)
                    })}</div>}
                </ConsoleLine>
            )
    }
}

export default SimulateCommand;
