import React, { Component } from 'react';
import Output from "./Output";
import StartupCommand from "./console/commands/StartupCommand";
import Editor from "./Editor";
import SimulateCommand from "./console/commands/SimulateCommand";
import SpecificSimulateCommand from "./console/commands/SpecificSimulateCommand";


class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '.title dual rc ladder\n' +
          'R1 int in 10k\n' +
          'V1 in 0 dc 0 PULSE (0 5 1u 1u 1u 1 1)\n' +
          'R2 out int 1k\n' +
          'C1 int 0 1u\n' +
          'C2 out 0 100n\n' +
          '\n' +
          '.control\n' +
          'tran 50u 300u\n' +
          'print v1#branch\n' +
          'print v1#branch in\n' +
          'let k = length(time) - 1\n' +
          'print time[k] v(2)[k]\n' +
          '.endc\n' +
          '.end\n',
      commands: [],
      completed_commands: [],
      currIdx: -1,
      onProcess: false,
      code: ''
    };

    this.counterWorker = undefined;
  }

  async componentDidMount() {
    this.start_background_job();
    await this.addNewCommands(<StartupCommand addNewCommands={this.specificSimulate} changeOnProcess={this.changeProcessTo}/>);
  }

  start_background_job(){
    this.counterWorker =  new Worker('counter.js');
    this.counterWorker.addEventListener('message', e => {
      if(this.state.onProcess === false && this.state.commands.length !== this.state.currIdx+1){
        this.setState({completed_commands: this.state.completed_commands.concat([this.state.commands[this.state.currIdx+1]]), currIdx: this.state.currIdx+1});
      }
    });
    this.counterWorker.postMessage({});
  }

  changeProcessTo = (to) => {
    this.setState({onProcess: to});
  };

  simulate = async (code) => {
    this.setState({code: code});
    await this.addNewCommands(<SimulateCommand addNewCommands={this.specificSimulate.bind(this)} changeOnProcess={this.changeProcessTo} code={code}/>);
  };

  specificSimulate = async (figure) => {
    await this.addNewCommands(<SpecificSimulateCommand addNewCommands={this.specificSimulate.bind(this)} figure={figure} changeOnProcess={this.changeProcessTo} code={this.state.code}/>);
  };

  addNewCommands = async (command) => {
    this.setState({commands: this.state.commands.concat([command])});
  }

  clearAll = () => {
    this.setState({commands: [], completed_commands: [], currIdx: -1, onProcess:false})
  }

  render() {

    return (
        <div style={{height: "100%"}}>
          <div style={{width:"calc(50% - 1px)", float:"left", borderRight:"1px solid #d3dce6", height: "100%"}}>
            <Editor onProcess={this.state.onProcess} simulate={this.simulate}/>
          </div>

          <div style={{width:"calc(50% - 1px)", float:"left", height: "100%"}}>
            <Output clear={this.clearAll} onProcess={this.state.onProcess} completed_commands={this.state.completed_commands}/>
          </div>
        </div>

    );
  }
}

export default Main;
