import React, { Component } from 'react';
import Loader from "react-loader-spinner";


class ConsoleLine extends Component {

    render() {

        return (
            <div>
                <div>
                    <div style={{float: "left", color: "#137d13"}}>cloud-server:~$&nbsp;</div>
                    <div style={{float: "left"}}>{this.props.command}</div>
                </div>
                <div style={{clear: 'both', paddingTop: '5px'}}>
                    {this.props.output === null ? <Loader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={30}
                        width={30}
                    />:  <div>{this.props.hideOutput === true? <div/>: this.props.output.split('\n').map(line => {
                        return (<div>{line}</div>)
                    })}</div>}
                    {this.props.children}
                </div>
                <br />
            </div>
        );
    }
}

export default ConsoleLine;
