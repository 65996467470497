import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Main  from './pages/main/Main';
import Terms  from './pages/terms/Terms';


class App extends Component {

  render() {

    return (
        <div style={{height: "95%"}}>
          <Route exact path="/" render={() => (<Redirect to="/main" />)}/>
          <Route path="/main" component={Main}/>
          <Route path="/terms" component={Terms}/>
        </div>
    );
  }
}

export default App;
