import React, { Component } from 'react';


class Pretty_NGSPICE_CLI_ERROR extends Component {

    render() {

        return (
            <div style={{paddingTop: "10px"}}>
                <div style={{color: '#015ec0', fontWeight: "900", fontSize: "18px"}}>{this.props.title}</div>
                <div style={{paddingTop: "8px"}}/>

                <div style={{border: "1px dotted #ccc", lineHeight: "22px", color: "#ddd", paddingTop: "5px"}}>
                    {
                        this.props.contents.map((dataLine, index) => {
                            return <div>{dataLine}</div>
                        })
                    }
                </div>
            </div>
        );
    }
}

export default Pretty_NGSPICE_CLI_ERROR;
