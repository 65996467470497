import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts'
import Loader from "react-loader-spinner";
import NodeSelector from "../../../modal/NodeSelector";
import Pretty_NGSPICE_CLI_ERROR from "./Pretty_NGSPICE_CLI_ERROR";

class Pretty_TABULARCONTENTS extends Component {

    constructor(props) {
        super(props);
        this.state = {
            parsedObjects: [],
            nodes: []
        }

        this.options = {
            chart: {
                height: 350,
                    type: 'line',
                    zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                },
            },

        };
    }

    componentDidMount() {
        // this.props.contents => [#print1, #print2]
        let tempParsedObjects = [];
        let nodes = [];

        if(this.props.title === "INITIAL_TRANSIENT_SOLUTION"){

            for(let eachNodeIdx=0; eachNodeIdx<this.props.contents.length; eachNodeIdx++) {
                nodes.push(this.props.contents[eachNodeIdx]["node"])

            }
        }

        for(let bulkPrintedIdx=0; bulkPrintedIdx<this.props.contents.length; bulkPrintedIdx++){
            let tabularParsedData = [];

            let contents = this.props.contents[bulkPrintedIdx]['values']; // [{time}, {branch1}, {branch2}]

            let title = [];
            let specificTitle = [];
            let tempChartData = []
            let xData = [];

            for(let eachNodeIdx=0; eachNodeIdx<contents.length; eachNodeIdx++){

                if(contents[eachNodeIdx]['key'] !== undefined){
                    specificTitle.push(contents[eachNodeIdx]['key']);

                }
                else if(contents[eachNodeIdx]['data_name'] !== undefined){
                    title.push(contents[eachNodeIdx]['data_name']);
                }
                if(contents[eachNodeIdx]['data_name'] === 'time'){
                    xData = contents[eachNodeIdx]['values']
                }else{
                    tempChartData.push({
                        name: contents[eachNodeIdx]['data_name'],
                        data: contents[eachNodeIdx]['values']
                    })
                }
            }

            for(let dataIdx=0; dataIdx<contents[0].values.length; dataIdx++){ // contents[0].values == voltage data(5.00v)
                let temp = [];

                for(let eachNodeIdx=0; eachNodeIdx<contents.length; eachNodeIdx++){ // 여러개의 노드(모듈)이 있을 경우, 같은 시간에 대해 이를 추가
                    temp.push(contents[eachNodeIdx]['values'][dataIdx]);
                }
                tabularParsedData.push(temp); // 예를 들어 노드가 2개 일경우 [[노드 1 볼트, 노드 2 볼트, 노드 3 볼트]] 형식
            }

            let x = { categories: xData }

            let newX = { ...this.options, xaxis : x };
            tempParsedObjects.push
            (
                {
                    title: title,
                    specificTitle: specificTitle,
                    value: tabularParsedData,
                    tempChartData: tempChartData,
                    tempChartOptions: newX
                }
            )
        }
        this.makeCsvStr(tempParsedObjects);
        this.setState({parsedObjects: tempParsedObjects, nodes: nodes});
    }

    makeCsvStr(tp){
        let csv = []
        tp.map((dataLine, index) => {
            let temp = [];
            if(dataLine.title.length > 0){
                temp.push(dataLine.title.join(","))
            }else if(dataLine.specificTitle.length > 0){
                temp.push(dataLine.specificTitle.join(","))
            }
            dataLine.value.map(line => {
                temp.push(line.join(','));
            })

            csv.push(temp.join('\r\n'))


        });
        return csv
    }

    downloadTxtFile = (index) => { // TODO: 속도 개선(매번 계산이 아닌, 미리 계산)

        const list_of_csv = this.makeCsvStr(this.state.parsedObjects)[index];
        let buffer = Buffer.from(list_of_csv)
        let read = buffer.toString('utf8')

        const element = document.createElement("a");
        const file = new Blob([[read]]);
        element.href = URL.createObjectURL(file);
        element.download = "myFile.csv";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

    }

    render() {

        return (
            <div style={{paddingTop: "10px"}}>
                <div style={{color: '#015ec0', fontWeight: "900", paddingTop:"5px", fontSize: "18px"}}>{this.props.title}</div>

                {
                    this.state.parsedObjects.map((dataLine, index) => {
                        return <div style={{paddingTop: "5px"}}>
                            <div style={{color: '#015ec0', fontWeight: "700", paddingTop:"5px"}}>
                                # {
                                    this.props.contents[index]["key"] !== undefined?
                                        this.props.contents[index]["key"]:this.props.contents[index]["node"]
                                }
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            dataLine.title.map(line => {
                                                return <th>{line}</th>
                                            })
                                        }
                                        {
                                            dataLine.specificTitle.map(line => {
                                                return <th>{line}</th>
                                            })
                                        }
                                    </tr>
                            </thead>
                                <tbody>
                                    {
                                        dataLine.value.map(line => {
                                            return (<tr>
                                                {
                                                    line.map( line2 => {
                                                        return (<th>{line2}</th>)
                                                    })
                                                }
                                            </tr>)}
                                    )}
                                </tbody>
                            </table>
                            <ReactApexChart
                                style={{width: "90%"}}
                                options={dataLine.tempChartOptions}
                                series={dataLine.tempChartData}
                                type="line"
                                height={350} />

                                    <div onClick={() => this.downloadTxtFile(index)}
                                         style={{width: "120px", backgroundColor: "#1D6F42", height: "40px", borderRadius: '5px'}}>
                                        <div style={{textAlign: "center", color: 'white', lineHeight: '40px', fontSize: "13px", fontWeight: "500"}}>Download as CSV</div>
                                    </div>

                        </div>
                    })
                }
            </div>
        );
    }
}

export default Pretty_TABULARCONTENTS;
