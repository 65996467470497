import React, { Component } from 'react';
import Console from "./console/Console";
import StartupCommand from "./console/commands/StartupCommand";


class Output extends Component {

    constructor(props) {
        super(props);
        this.state = {
            onProcess: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.onProcess !== this.state.onProcess) {
            this.setState({onProcess: this.props.onProcess})
        }
    }


    render() {
            return (
                <div style={{height: "100%"}}>
                    <div style={{ borderBottom: "1px solid #ccc", width: "100%", height: "50px", textAlign: "center"}}>
                        <div style={{width: "70px", height: "50px", lineHeight: "50px", float: "left"}}>
                            <div style={{color : "#333"}}>Output</div>
                        </div>
                        <div style={{float: "left", marginLeft: "1px", height: "100%", display: "flex", alignItems: "center"}}>
                            <div onClick={this.props.clear} style={{width: "50px", height: "25px", borderRadius: '5px', border: '1px solid red', cursor: "pointer"}}>
                                <div style={{color: 'red', lineHeight: '25px', fontSize: "12px", fontWeight: "500"}}>Clear</div>
                            </div>
                        </div>
                        <div style={{float: "right", marginRight: "30px", height: "100%", display: "flex", alignItems: "center"}}>
                            {/*<div style={{width: "100px", backgroundColor: "#015ec0", height: "40px", borderRadius: '5px'}}>*/}
                            {/*    <div style={{color: 'white', lineHeight: '40px', fontSize: "18px", fontWeight: "500"}}>Simulate</div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <Console onProcess={this.state.onProcess} commands={[this.props.completed_commands]}/>
                </div>
            )
    }
}

export default Output;
