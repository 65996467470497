import React, { Component } from 'react';
import Output from "./Output";
import StartupCommand from "./console/commands/StartupCommand";
import Loader from "react-loader-spinner";


class Editor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '.title dual rc ladder\n' +
          'R1 int in 10k\n' +
          'V1 in 0 dc 0 PULSE (0 5 1u 1u 1u 1 1)\n' +
          'R2 out int 1k\n' +
          'C1 int 0 1u\n' +
          'C2 out 0 100n\n' +
          '\n' +
          '.control\n' +
          'tran 50u 300u\n' +
          'print v1#branch\n' +
          'print v1#branch in\n' +
          'let k = length(time) - 1\n' +
          'print time[k] v(2)[k]\n' +
          '.endc\n' +
          '.end\n',
      onProcess: false
    };

    this.handleChange = this.handleChange.bind(this);
  }


  handleChange(event) {
    this.setState({value: event.target.value});
  }

  componentDidUpdate(prevProps) {
    if (this.props.onProcess !== this.state.onProcess) {
      this.setState({onProcess: this.props.onProcess})
    }
  }

  render() {

    return (
        <div style={{height: "100%"}}>
          <div style={{ borderBottom: "1px solid #ccc", width: "100%", height: "50px", textAlign: "center"}}>
            <div style={{width: "110px", height: "50px", backgroundColor: "#f5f5f5", borderBottom: "2px solid #015ec0", lineHeight: "50px", float: "left"}}>
              <div style={{color : "#333"}}>myCircuit.cir</div>
            </div>
            <div style={{float: "right", marginRight: "30px", height: "100%", display: "flex", alignItems: "center"}}>
              {this.state.onProcess === false?
                  <div onClick={async () => {await this.props.simulate(this.state.value)}}
                       style={{width: "100px", backgroundColor: "#015ec0", height: "40px", borderRadius: '5px'}}>
                    <div style={{color: 'white', lineHeight: '40px', fontSize: "18px", fontWeight: "500", cursor: "pointer"}}>Simulate</div>
                  </div>: <div style={{width: "100px", backgroundColor: "#5d5d5d", height: "40px", borderRadius: '5px'}}>
                <div style={{color: 'white', lineHeight: '40px'}}>
                  <Loader
                    type="ThreeDots"
                    color="#ffffff"
                    height={40}
                    width={25}/>
                </div>
              </div>}

            </div>
          </div>
            <div style={{height: "calc(100% - 50px)"}}>
              <textarea
                  style={{width: "calc(100% - 20px)", height: "100%", border: "none", outline: "none", resize: "none", padding: "10px"}}
                  value={this.state.value}
                  onChange={this.handleChange}
              />
            </div>
          </div>

    );
  }
}

export default Editor;
