import React, { Component } from 'react';
import Modal from 'react-modal';
import { Checkbox, CheckboxGroup } from '@trendmicro/react-checkbox';


class NodeSelector extends Component{

    constructor(props) {
        super(props);
        this.state = {
            value: [],
            selected: "",
            nodes: [],
            tds: [],
            isOpen: true
        }
    }

    componentDidMount() {
        let tds = [];
        let temp = [];
        for(let i=0; i<this.props.nodes.length; i++){
            temp.push(this.props.nodes[i])
            if(temp.length === 5){
                tds.push(JSON.parse(JSON.stringify(temp)));
                temp.length = 0;
            }
        }
        tds.push(temp);
        this.setState({nodes: this.props.nodes, tds: tds})
    }

    reSimulateWithSpecificParameters(){
        this.props.addNewCommands(this.state.value);
        this.setState({isOpen: false});
    }

    render() {
        console.log("curr nodes in is")
        console.log(this.state.nodes)
        return (
            <Modal
                isOpen={this.state.isOpen}
                style={{content: {width:"550px", height: "250px", top: "calc(50% - 300px)", left: "calc(50% - 300px)", padding: "0"}}}
                contentLabel="Example Modal">
                    <div style={{backgroundColor: "#015ec0", padding: "3px", color: "white"}}>Plot</div>
                    <div style={{padding:"5px"}}>
                        <div style={{fontSize: "12px"}}>
                            Please select the parameters. If there's no parameters what you want, you should print from codes.
                        </div>
                        <div>
                            <div style={{marginTop: "10px"}}>
                                <div>Parameters</div>
                                <div style={{fontSize: "11px", marginTop: "10px", color:"#ccc"}}>
                                    {
                                        this.state.value.length !== 0?
                                        <div>Simulate "print {this.state.value.join(" ")}" codes.</div>:<div/>
                                    }
                                </div>
                                <div style={{paddingTop: "10px"}}>
                                    <CheckboxGroup
                                        name="nodes"
                                        depth={this.props.nodes.length}
                                        value={this.state.value}
                                        onChange={(value, event) => {
                                            this.setState({ value: value });
                                        }}
                                    >
                                        <div>
                                            {this.state.tds.map(
                                                tr => {
                                                    return (<tr style={{fontSize: "13px"}}>{
                                                        tr.map(td => {
                                                            return (
                                                                <td style={{width: "100px"}}>
                                                                    <Checkbox label={td} value={td} />
                                                                </td>
                                                            )
                                                        })
                                                    }</tr>)
                                                }
                                            )}
                                        </div>
                                    </CheckboxGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{top: "100%", transform: "translateY(-100%)", position: "absolute", padding: "10px", width: "95%"}}>
                        <div style={{width: "100%"}}>
                            <div style={{float: "right"}}>
                                <div
                                    onClick={() => this.setState({isOpen: false})}
                                    style={{float: "left", color:"#bb0f0f", cursor: "pointer"}}>
                                    Close
                                </div>
                                <div
                                    onClick={() => this.reSimulateWithSpecificParameters()}
                                    style={{float: "left", marginLeft: "10px", cursor: "pointer", fontWeight: "900", color: "#015ec0"}}>
                                    Simulate
                                </div>
                            </div>
                        </div>
                    </div>
            </Modal>
        );
    }
}

export default NodeSelector;
