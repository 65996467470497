import React, { Component } from 'react';
import ConsoleLine from "./ConsoleLine";
import NodeSelector from "../../modal/NodeSelector";


class Console extends Component{

    constructor(props) {
        super(props);
        this.state = {
            commands: [],
            onProcess: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.commands !== this.state.commands) {
            this.setState({commands: this.props.commands})
        }

        if (this.props.onProcess !== this.state.onProcess) {
            this.setState({onProcess: this.props.onProcess})
        }
    }

    render() {
        return (
            <div style={{height: "100%", overflow: "scroll"}}>
                <div style={{padding : "10px"}}>
                    {
                        this.state.commands.length === 0 ? <ConsoleLine command={"_"} output={""}/>:
                            this.state.commands.map(
                                command => {
                                    return (<div>{command}</div>)
                                }
                            )
                    }
                    {
                        this.state.commands.length !== 0 && !this.state.onProcess ?
                            <ConsoleLine command={"_"} output={""}/>: <div/>
                    }
                    <div style={{paddingBottom: "10px"}}/>
                </div>
            </div>
        );
    }
}

export default Console;
